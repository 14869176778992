import { gql } from 'graphql-tag'
import { getUserState } from '@/firebase'
import axios from 'axios'
import store from '@/store'

const url = 'https://api-corp.saobento.com.br'

const getAuthToken = async () => {
	const userState = await getUserState()
	return 'Bearer ' + userState.accessToken
}

const getProcesses = async apolloClient => {
	const codcoligada = parseInt(store.state.contracts.selectedContract.codcoligada)
	const numvenda = parseInt(store.state.contracts.selectedContract.num_venda)
	let processes = []
	await apolloClient
		.query({
			query: gql`
				query getProcess($codcoligada: Int, $numvenda: Int) {
					process(
						where: {
							_and: { codcoligada: { _eq: $codcoligada }, num_venda: { _eq: $numvenda } }
						}
						order_by: { num_proces: desc }
					) {
						cod_empresa
						codcoligada
						descricao
						etapa_desc
						firebaseuid
						id_processo
						is_canceled
						is_finished
						num_proces
						num_venda
						historic(order_by: { num_seq_movto: desc }) {
							num_seq_movto
							etapa_name
							etapa_desc
							data_execucao
							is_start
							is_end
							is_running
							is_canceled
						}
					}
				}
			`,
			variables: {
				codcoligada: codcoligada,
				numvenda: numvenda,
			},
			fetchPolicy: 'no-cache',
		})

		.then(response => {
			processes = response.data.process
		})

	return processes
}

export const solicitarDemarcacao = async (contract, alvara, extensaoAlvara) => {
	const codcoligada = contract.codcoligada
	const numvenda = contract.num_venda
	let data = null
	let errors = null

	try {
		let res = await axios.post(
			`${url}/portal-cliente/marcacao-lote`,
			{
				codColigada: codcoligada,
				numVenda: numvenda,
				alvara: alvara,
				formatoArquivo: extensaoAlvara
			},
			{ headers: { Authorization: await getAuthToken() } }
		)

		data = res.data
	} catch (error) {
		if (error.response.status == 403) {
			errors = error.response.data.detail
		} else {
			errors = error.response.data
		}
	}

	return {
		data: data,
		error: errors,
	}
}

const solicitarProgramaRocada = async contract => {
	const codcoligada = contract.codcoligada
	const numvenda = contract.num_venda
	let data = null
	let errors = null

	try {
		let res = await axios.post(
			'https://api.saobento.com.br/cliente/processos/programa-rocada',
			{
				codcoligada: codcoligada,
				numvenda: numvenda,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)

		data = res.data
	} catch (error) {
		errors = error.response.data
	}

	return {
		data: data,
		error: errors,
	}
}

const CriarProcessoTransferencia = async body => {
	let data = null
	let errors = null

	try {
		let res = await axios.post(
			`${url}/portal-cliente/transferencia-comprador`, body, {
				headers: { Authorization: await getAuthToken() }
			}
		)
		data = res.data
	} catch (error) {
		if (error.response.status == 403) {
			errors = error.response.data.detail
		} else {
			errors = error.response.data
		}
	}

	return {
		data: data,
		error: errors,
	}
}

const BuscarProcessoTransferencia = async dados => {
	let data = null
	let errors = null

	try {
		let res = await axios.get(
			`${url}/portal-cliente/transferencia-comprador?contrato=${dados.contrato}&codColigada=${dados.codColigada}&numVenda=${dados.numVenda}`, {
				headers: { Authorization: await getAuthToken() }
			}
		)
		data = res.data
	} catch (error) {
		errors = error.response.data
	}

	return {
		data: data,
		error: errors,
	}
}

const ListaProfissoes = async () => {
	let data = null
	let errors = null

	try {
		let res = await axios.get(
			`${url}/portal-cliente/profissoes`, {
				headers: { Authorization: await getAuthToken() }
			}
		)
		data = res.data
	} catch (error) {
		if (error.response.status == 403) {
			errors = error.response.data.detail
		} else {
			errors = error.response.data
		}
	}

	return {
		data: data,
		error: errors,
	}
}

const ListaMunicipios = async () => {
	let data = null
	let errors = null

	try {
		let res = await axios.get(
			`${url}/portal-cliente/municipios`, {
				headers: { Authorization: await getAuthToken() }
			}
		)
		data = res.data
	} catch (error) {
		if (error.response.status == 403) {
			errors = error.response.data.detail
		} else {
			errors = error.response.data
		}
	}

	return {
		data: data,
		error: errors,
	}
}

const ListaCompradores = async (numvenda, codcoligada) => {
	let data = null
	let errors = null

	try {
		let res = await axios.get(
			`${url}/portal-cliente/compradores?codColigada=${codcoligada}&numVenda=${numvenda}`, {
				headers: { Authorization: await getAuthToken() }
			}
		)
		data = res.data
	} catch (error) {
		if (error.response.status == 403) {
			errors = error.response.data.detail
		} else {
			errors = error.response.data
		}
	}

	return {
		data: data,
		error: errors,
	}
}

export default () => ({
	getProcesses,
	solicitarDemarcacao,
	solicitarProgramaRocada,
	CriarProcessoTransferencia,
	BuscarProcessoTransferencia,
	ListaProfissoes,
	ListaMunicipios,
	ListaCompradores
})
